@mixin fullscreen {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@keyframes ani-wiggle {
  0% {
    transform: translate(1vw, 1vh);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ani-wiggle-2 {
  0% {
    transform: rotate(135deg)translate(1vw, 1vh) ;
  }
  100% {
    transform: rotate(135deg) translate(0, 0);
  }
}

@keyframes jumping {
  0% {
    transform: translate(0, -1vh);
  }
  100% {
    transform: translate(0, 0vh);
  }
}

@keyframes jumping-2 {
  0% {
    transform: translate(0, -48vh) scale(2);
  }
  100% {
    transform: translate(0, -50vh) scale(2);
  }
}

@keyframes horizon-sprite {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 100%;
  }
}

@keyframes slide-BL-in {
  0% {
    transform: translate(-100%, 100%);
  }
  60% {
    transform: translate(0, 0);
  }

}

@keyframes scale-out {
  0% {
    transform: scale(.1);
    opacity: 0;
  }
  60% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes crow-fly {
  0% {
    left: 50%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    left: -50%;
    opacity: 0;
  }
}

@function textBorder ($color, $thickness) {
  @return -$thickness (-$thickness) 0 $color, $thickness (-$thickness) 0 $color, -$thickness $thickness 0 $color, $thickness $thickness 0 $color;
}

$result-during: .5s;
$result-delay: 3s;
@function result-delay ($index, $offset: 0) {
  @return ($result-during + $result-delay) * ($index - 1) + $result-delay + $offset;
}

@font-face {
  font-family: '王漢宗波卡體一空陰';
  src: local('王漢宗波卡體一空陰'), url("fonts/WCL-03-min.ttf") format('truetype');
}

* {
  box-sizing: border-box;
}

html, body {
  background-color: #0F1837;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 王漢宗波卡體一空陰;
}

.hide {
  display: none !important;
}

.intro {
  @include fullscreen;
  background: url("images/intro.jpg") center center / auto 95% no-repeat;
  cursor: pointer;
  &.introduction {
    background: url("images/introduction.jpg") center center / auto 95% no-repeat;
  }
  &.rules {
    background: url("images/rules.jpg") center center / auto 95% no-repeat;
  }
}

.character-img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &.led {
    background-image: url('images/LED燈具.png');
  }
  &.audio {
    background-image: url('images/音響.png');
  }
  &.dryer {
    background-image: url('images/烘衣機.png');
  }
  &.water-dispenser {
    background-image: url('images/開飲機.png');
  }
  &.tv {
    background-image: url('images/電視.png');
  }
  &.microwave {
    background-image: url('images/微波爐.png');
  }
  &.water-pot {
    background-image: url('images/熱水瓶.png');
  }
  &.electric-pot {
    background-image: url('images/電鍋.png');
  }
}

.number-img {
  >* {
    display: inline-block;
  }
  .digit-0 {
    background: url('images/0.png') center center/ 100% 100% no-repeat;
  }
  .digit-1 {
    background: url('images/1.png') center center/ 100% 100% no-repeat;
  }
  .digit-2 {
    background: url('images/2.png') center center/ 100% 100% no-repeat;
  }
  .digit-3 {
    background: url('images/3.png') center center/ 100% 100% no-repeat;
  }
  .digit-4 {
    background: url('images/4.png') center center/ 100% 100% no-repeat;
  }
  .digit-5 {
    background: url('images/5.png') center center/ 100% 100% no-repeat;
  }
  .digit-6 {
    background: url('images/6.png') center center/ 100% 100% no-repeat;
  }
  .digit-7 {
    background: url('images/7.png') center center/ 100% 100% no-repeat;
  }
  .digit-8 {
    background: url('images/8.png') center center/ 100% 100% no-repeat;
  }
  .digit-9 {
    background: url('images/9.png') center center/ 100% 100% no-repeat;
  }
}

.card-img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &.air-conditioner {
    background-image: url('images/card-air-conditioner.jpg');
  }
  &.audio {
    background-image: url('images/card-audio.jpg');
  }
  &.computer {
    background-image: url('images/card-computer.jpg');
  }
  &.dryer {
    background-image: url('images/card-dryer.jpg');
  }
  &.fan {
    background-image: url('images/card-fan.jpg');
  }
  &.hair-dryer {
    background-image: url('images/card-hair-dryer.jpg');
  }
  &.heater {
    background-image: url('images/card-heater.jpg');
  }
  &.led {
    background-image: url('images/card-led.jpg');
  }
  &.microwave {
    background-image: url('images/card-microwave.jpg');
  }
  &.refrigerator {
    background-image: url('images/card-refrigerator.jpg');
  }
  &.rice-pot {
    background-image: url('images/card-rice-pot.jpg');
  }
  &.tv {
    background-image: url('images/card-tv.jpg');
  }
  &.washing-machine {
    background-image: url('images/card-wash-machine.jpg');
  }
  &.water-dispenser {
    background-image: url('images/card-water-dispenser.jpg');
  }
  &.water-heater {
    background-image: url('images/card-water-heater.jpg');
  }
  &.water-pot {
    background-image: url('images/card-water-pot.jpg');
  }
  &.advance {
    &.air-conditioner {
      background-image: url('images/card-advance-air-conditioner.jpg');
    }
    &.audio {
      background-image: url('images/card-advance-audio.jpg');
    }
    &.computer {
      background-image: url('images/card-advance-computer.jpg');
    }
    &.dryer {
      background-image: url('images/card-advance-dryer.jpg');
    }
    &.fan {
      background-image: url('images/card-advance-fan.jpg');
    }
    &.hair-dryer {
      background-image: url('images/card-advance-hair-dryer.jpg');
    }
    &.heater {
      background-image: url('images/card-advance-heater.jpg');
    }
    &.led {
      background-image: url('images/card-advance-led.jpg');
    }
    &.microwave {
      background-image: url('images/card-advance-microwave.jpg');
    }
    &.refrigerator {
      background-image: url('images/card-advance-refrigerator.jpg');
    }
    &.rice-pot {
      background-image: url('images/card-advance-rice-pot.jpg');
    }
    &.tv {
      background-image: url('images/card-advance-tv.jpg');
    }
    &.wash-machine {
      background-image: url('images/card-advance-wash-machine.jpg');
    }
    &.water-dispenser {
      background-image: url('images/card-advance-water-dispenser.jpg');
    }
    &.water-heater {
      background-image: url('images/card-advance-water-heater.jpg');
    }
    &.water-pot {
      background-image: url('images/card-advance-water-pot.jpg');
    }
  }
}

.money-img {
  position: absolute;
  left: 0;
  bottom: 0;
  > * {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    animation: slide-BL-in 2s ease-out 2 alternate forwards;
  }
  .dolors100 {
    background-image: url('images/dolors100.png');
  }
  .dolors500 {
    background-image: url('images/dolors500.png');
  }
  .dolors1000 {
    background-image: url('images/dolors1000.png');
  }
  .dolors3000 {
    background-image: url('images/dolors3000.png');
  }
  .dolors3000 {
    background-image: url('images/dolors9000.png');
  }
}

.cards {
  display: grid;
  grid-template-columns: 35% 1fr;
  grid-template-rows: repeat(5, 20%);
  height: 100%;
  padding: 0;
  button.next {
    appearance: none;
    width: 12vh;
    height: 12vh;
    margin-top: 8vh;
    outline: 0;
    border: 0;
    grid-column: 1;
    justify-self: center;
    cursor: pointer;
  }
  &.normal {
    button.next {
      background: url("images/arrow_b.png") center center / 100% 100% no-repeat;
    }
  }
  &.advance {
    button.next {
      background: url("images/arrow_o.png") center center / 100% 100% no-repeat;
    }
  }
  .subject {
    grid-column: 1;
    justify-self: center;
    font-size: 7vh;
    white-space: nowrap;
    color: #000;
  }
  .number-img {
    grid-column: 1;
    justify-self: center;
    white-space: nowrap;
  }
  .label {
    grid-column: 1;
    justify-self: center;
    font-size: 7vh;
    color: #000;
  }
  .card-img {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 4;
    transition: transform .3s;
    &.none {
      border-radius: 5vh;
      border: 10px dashed #fa0;
    }
    &.top {
      z-index: 100;
    }
  }
  &.flipping {
    .card-img {
      transform: translateX(22vw);
      +.card-img {
        transform: translateX(0);
      }
    }
  }
}

.character-selection {
  @include fullscreen;
  display: grid;
  grid-template-columns: 1fr 20%;
  h1 {
    color: #F4DB5C;
    text-align: center;
    font-size: 8vh;
    margin: 2vh;
  }
  .bg-solid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;
    background-color: #F4DB5C;
    border-radius: 8vh;
    position: absolute;
    top: 14vh;
    bottom: 5vh;
    left: 3vw;
    right: 23vw;
    padding: 3vh 4vw;
    text-align: center;
    .character {
      display: inline-block;
      position: relative;
      margin: 0;
      .name {
        font-size: 4vh;
        display: inline-block;
        background-color: #FFF;
        padding: 0.3em;
        border-radius: 0.3em;
        margin: .3em;
        white-space: nowrap;
      }
    }
  }
  section:first-child {
    .character-img {
      max-width: 100%;
      max-height: 100%;
      &.audio {
        transform: scale(0.8);
      }
      &.dryer {
        transform: scale(0.8);
      }
      &.water-dispenser {
        transform: scale(0.95);
      }
      &.tv {
        transform: scale(0.7);
      }
      &.microwave {
        transform: scale(0.7);
      }
      &.water-pot {
        transform: scale(0.8);
      }
      &.electric-pot {
        transform: scale(0.8);
      }
    }
  }
  section:nth-child(2) {
    .character-img {
      &.audio {
        transform: scale(1.1);
      }
      &.dryer {
        transform: scale(1.1);
      }
      &.water-dispenser {
        transform: scale(1.2);
      }
      &.tv {
        transform: scale(1.3);
      }
      &.microwave {
        transform: scale(1.5);
      }
      &.electric-pot {
        transform: scale(1.3);
      }
    }
  }
  h2 {
    color: #F4DB5C;
    font-size: 5vh;
    text-align: center;
    margin: 2vh;
  }
  .player, & .npc1, & .npc2 {
    width: 18vh;
    height: 18vh;
    margin: 2vh auto;
    padding: 1vh;
    background-color: #F4DB5C;
    border-radius: 2vh;
    text-align: center;
    .character-img {
      display: inline-block;
    }
  }
  .btn-next {
    appearance: none;
    display: block;
    outline: none;
    border: none;
    margin: 4vh auto;
    width: 32vh;
    height: 12vh;
    opacity: .7;
    cursor: pointer;
    background: url('images/start.png') center center/ 100% 100% no-repeat;
  }
  &:not(.checked) {
    .character {
      cursor: pointer;
      &.selected:after {
        display: block;
        position: absolute;
        width: 16vh;
        height: 16vh;
        right: -3vw;
        bottom: -5vh;
        content: "";
        background: url("images/hand.png") center / 100% no-repeat;
        animation: ani-wiggle 1s ease-out infinite alternate;
      }
    }
    .btn-next {
      cursor: pointer;
      opacity: 1;
    }
  }
}

.monopoly-map {
  @include fullscreen;
  .bg {
    position: relative;
    margin: 5vh auto;
    height: 90vh;
    width: 127vh;
    background: url('images/map.jpeg') center center/ 100% 100% no-repeat;
    .characters {
      width: 100%;
      height: 100%;
      .character-wrapper {
        @include fullscreen;
        transform: translate(2vw,5vh);
        transition: transform .5s ease-out;
        &.current {
          z-index: 100;
          pointer-events: none;
          .jumping-wrapper {
            animation: jumping 1s ease-in-out infinite alternate;
          }
        }
        .jumping-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        &.character-img {
          transform-origin: center bottom;
        }
        &:not(.first-column):not(.last-column).last-row {
          transform: translate(2vw, 2vh);
        }
        &.first-column {
          transform: translate(3vw, 5vh);
        }
        ~.collision {
          &:not(.freeze) {
            &.first-column {
              transform: translate(6vw, 5vh);
              +.collision:not(.freeze) {
                transform: translate(1vw, 5vh);
              }
            }
            &.last-column {
              transform: translate(4vw, 5vh);
              +.collision:not(.freeze) {
                transform: translate(-1vw, 5vh);
              }
            }
            &:not(.first-column):not(.last-column).first-row {
              transform: translate(2vw, 2vh);
              +.collision:not(.freeze) {
                transform: translate(2vw, 9vh);
              }
            }
            &:not(.first-column):not(.last-column).last-row {
              transform: translate(2vw, -2vh);
              +.collision:not(.freeze) {
                transform: translate(2vw, 7vh);
              }
            }
          }
        }
      }
      .character-img {
        position: absolute;
        transition: all .5s;
        &.msg {
          &::before {
            display: block;
            position: absolute;
            content: attr(data-message);
            background: url('images/popup1.png') center center/ 100% 100% no-repeat;
            width: 20vh;
            height: 15vh;
            font-size: 2.2vh;
            left: -20vh;
            top: -6vh;
            padding: 0.5em 1em;
            line-height: 1.2em;
            box-sizing: border-box;
          }
          &.first-row::before {
            top: -1vh;
          }
          &.first-column::before {
            background: url('images/popup2.png') center center/ 100% 100% no-repeat;
            top: -2vh;
            left: auto;
            right: -20vh;
          }
        }
        &.audio {
          transform: scale(0.8);
        }
        &.dryer {
          transform: scale(0.8);
        }
        &.water-dispenser {
          transform: scale(0.95);
        }
        &.tv {
          transform: scale(0.7);
        }
        &.microwave {
          transform: scale(0.9);
        }
        &.water-pot {
          transform: scale(0.8);
        }
        &.electric-pot {
          transform: scale(0.8);
        }
      }
    }
    .flags {
      width: 100%;
      height: 100%;
      position: relative;
      .flag {
        position: absolute;
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform: translate(75%, 100%);
        &.audio {
          background-image: url("images/flag-audio-normal.png");
          &.advance {
            background-image: url("images/flag-audio-advance.png");
          }
        }
        &.led {
          background-image: url('images/flag-led-normal.png');
          &.advance {
            background-image: url('images/flag-led-advance.png');
          }
        }
        &.dryer {
          background-image: url("images/flag-dryer-normal.png");
          &.advance {
            background-image: url("images/flag-dryer-advance.png");
          }
        }
        &.water-dispenser {
          background-image: url("images/flag-water-dispenser-normal.png");
          &.advance {
            background-image: url("images/flag-water-dispenser-advance.png");
          }
        }
        &.tv {
          background-image: url("images/flag-tv-normal.png");
          &.advance {
            background-image: url("images/flag-tv-advance.png");
          }
        }
        &.microwave {
          background-image: url("images/flag-microwave-normal.png");
          &.advance {
            background-image: url("images/flag-microwave-advance.png");
          }
        }
        &.water-pot {
          background-image: url("images/flag-water-pot-normal.png");
          &.advance {
            background-image: url("images/flag-water-pot-advance.png");
          }
        }
        &.electric-pot {
          background-image: url("images/flag-rice-pot-normal.png");
          &.advance {
            background-image: url("images/flag-rice-pot-advance.png");
          }
        }
      }
    }
    .ui {
      .button {
        cursor: pointer;
      }
      .roll-dice {
        position: absolute;
        background: url('images/dice.png') center center/ 100% 100% no-repeat;
        width: 18%;
        height: 31%;
        left: 31%;
        top: 51%;
        &:after {
          display: block;
          position: absolute;
          content: '';
          width: 120%;
          height: 120%;
          right: -90%;
          bottom: -20%;
          background: url('images/hand.png') center / 100% no-repeat;
          animation: ani-wiggle .3s ease-out infinite alternate;
        }
      }
      .show-cash-bag {
        position: absolute;
        background: url('images/wallet.png') center center/ 100% 100% no-repeat;
        width: 10%;
        height: 16%;
        left: 70%;
        top: 62%;
      }
    }
    .dice-wrapper {
      @include fullscreen;
      top: -180%;
      perspective: 600vh;
      perspective-origin: 150% 150%;
      transform-style: preserve-3d;
      transform: rotateZ(30deg) rotateX(4deg);
      .dice {
        $dice-size: 30vh;
        position: absolute;;
        height: $dice-size;
        width: $dice-size;
        left: calc(50% - (#{$dice-size} / 2));
        top: calc(50% - (#{$dice-size} / 2));
        margin: 0;
        padding: 0;
        list-style-type: none;
        transform-style: preserve-3d;
        transition: transform 1.5s ease-out;
        &[data-roll='1'] {
          transform: rotateX(-360deg) rotateY(-720deg) rotateZ(-360deg);
        }
        &[data-roll='2'] {
          transform: rotateX(-270deg) rotateY(-720deg) rotateZ(-360deg);
        }
        &[data-roll='3'] {
          transform: rotateX(-360deg) rotateY(-810deg) rotateZ(-360deg);
        }
        &[data-roll='4'] {
          transform: rotateX(-360deg) rotateY(-630deg) rotateZ(-360deg);
        }
        &[data-roll='5'] {
          transform: rotateX(-450deg) rotateY(-720deg) rotateZ(-360deg);
        }
        &[data-roll='6'] {
          transform: rotateX(-360deg) rotateY(-900deg) rotateZ(-360deg);
        }
        li {
          background-color: #F00;
          display: grid;
          grid-template-areas:
            '_1 _2 _3'
            '_4 _5 _6'
            '_7 _8 _9';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          padding: 1rem;
          box-shadow: inset -5vh 5vh 10vh rgba(0, 0, 0, 0.3), inset 10vh -6vh 7vh rgba(0, 0, 0, 0.15);

          &:nth-child(1) {
            transform: rotate3d(0, 0, 0, 90deg) translateZ(($dice-size / 2));
            p {
              grid-area: _5;
            }
          }
          &:nth-child(2) {
            transform: rotate3d(-1, 0, 0, 90deg) translateZ(($dice-size / 2));
            p {
              grid-area: _1;
              +p {
                grid-area: _9;
              }
            }
          }
          &:nth-child(3) {
            transform: rotate3d(0, 1, 0, 90deg) translateZ(($dice-size / 2));
            p {
              grid-area: _1;
              +p {
                grid-area: _5;
                +p {
                  grid-area: _9;
                }
              }
            }
          }
          &:nth-child(4) {
            transform: rotate3d(0, -1, 0, 90deg) translateZ(($dice-size / 2));
            p {
              grid-area: _1;
              +p {
                grid-area: _3;
                +p {
                  grid-area: _7;
                  +p {
                    grid-area: _9;
                  }
                }
              }
            }
          }
          &:nth-child(5) {
            transform: rotate3d(1, 0, 0, 90deg) translateZ(($dice-size / 2));
            p {
              grid-area: _1;
              +p {
                grid-area: _3;
                +p {
                  grid-area: _5;
                  +p {
                    grid-area: _7;
                    +p {
                      grid-area: _9;
                    }
                  }
                }
              }
            }
          }
          &:nth-child(6) {
            transform: rotate3d(1, 0, 0, 180deg) translateZ(($dice-size / 2));
            p {
              grid-area: _1;
              +p {
                grid-area: _3;
                +p {
                  grid-area: _4;
                  +p {
                    grid-area: _6;
                    +p {
                      grid-area: _7;
                      + p {
                        grid-area: _9;
                      }
                    }
                  }
                }
              }
            }
          }

          p {
            align-self: center;
            background-color: #FFF;
            border-radius: 50%;
            height: 5.5vh;
            width: 5.5vh;
            margin: 0;
            justify-self: center;
          }
        }
      }
    }
    .money-img {
      top: -7%;
      left: -7%;
      bottom: unset;
      transform-origin: center;
      > * {
        animation: scale-out 1s ease-out 2 alternate forwards;
      }
    }
  }
  >.battery {
    position: absolute;
    top: 26vh;
    left: calc(50vw - 10vh);
    height: 40vh;
    width: 20vh;
    margin: 0;
    background: url('images/battery.png') 0 center / 500% auto no-repeat;
    animation: horizon-sprite 1.6s steps(4) forwards;
  }

  .cash-bag {
    @include fullscreen;
    background: url(images/confirm-popup.png) center center / 95% 100% no-repeat;
    h1 {
      font-size: 6vh;
      color: #0F1837;
      text-align: center;
      margin: 1.5em;
    }
    .button {
      appearance: none;
      position: absolute;
      top: 5vh;
      width: 32vh;
      height: 12vh;
      outline: 0;
      border: 0;
      cursor: pointer;
      &.back {
        background: url("images/prv_btn.png") center center / 100% 100% no-repeat;
        left: 10vw;
      }
      &.ok {
        background: url("images/nxt_btn_1.png") center center / 100% 100% no-repeat;
        right: 10vw;
      }
    }
    .characters {
      list-style: none;
      display: flex;
      justify-content: space-around;
      position: absolute;
      width: 100%;
      top: 30%;
      padding: 0 5%;
      .character-wrapper {
        cursor: pointer;
        padding: 5vh;
        border: 10px solid transparent;
        &.selected {
          border-color: #E7802A;
          border-radius: 10vh;
        }
      }
    }
    .name {
      font-size: 5vh;
      text-align: center;
      margin-bottom: -2.7em;
      margin-top: 1.3em;
    }
  }
  .character-cash-bag {
    background: url(images/confirm-popup.png) center center / 95% 100% no-repeat;
    .upper {
      margin: 2vh 22vw;
      padding-top: 11vh;
      height: 17vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .character-img {
        left: 10vw;
        top: 11vh;
        position: absolute;
      }
      .label {
        font-size: 10vh;
        color: #0F1837;
      }
      .number-img {
        white-space: nowrap;
        >[class^="digit-"] {
          display: inline-block;
        }
      }
    }
    .button {
      appearance: none;
      position: absolute;
      bottom: 1vh;
      width: 32vh;
      height: 12vh;
      outline: 0;
      border: 0;
      cursor: pointer;
      &.back {
        background: url("images/prv_btn.png") center center / 100% 100% no-repeat;
        left: 27vw;
      }
      &.leave {
        background: url("images/back.png") center center / 100% 100% no-repeat;
        right: 17vw;
      }
    }
    .card-set {
      height: 83vh;
      margin: 0;
      padding: 3vw;
      list-style: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 10vw;
    }
  }
  .confirm-screen {
    @include fullscreen;
    padding: 25vh 15vw 5vh;
    display: flex;
    &:not(.no-bg) {
      background: url("images/confirm-popup.png") center center / 95% 100% no-repeat;
    }
    &.pay {
      justify-content: center;
      .description {
        color: #4E70BE;
        padding: 0;
      }
    }
    &.sell {
      justify-content: center;
      padding: 2vh 15vw 5vh;
      .description {
        padding: 0;
        text-align: center;
        h1 {
          color: #4E70BE;
          margin: .8em 0 .5em 0;
        }
        .cards {
          height: 62vh;
        }
        .button.pay {
          position: absolute;
          top: 33vh;
          left: 65vw;
        }
      }
      .confirm {
        position: absolute;
        width: 70vw;
        height: 80vh;
        left: 15vw;
        top: 10vh;
        background: url('images/confirm-popup.png') center center / 100% 100% no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
          font-size: 8vh;
          font-weight: normal;
          color: #000;
          padding: 0;
          margin: 0 0 .2em 0;
          text-align: center;
        }
        .buttons {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }
        .button {
          appearance: none;
          border: none;
          width: 18vw;
          height: 14vh;
          &.yes {
            background: url('images/yes.png') center center/ 100% 100% no-repeat;
          }
          &.no {
            background: url('images/no.png') center center/ 100% 100% no-repeat;
          }
        }
      }
    }
    .description {
      width: 50.5vw;
      padding-left: 8em;
      text-align: center;
      h1 {
        color: #0F1837;
        font-weight: normal;
        margin: 0 0 1em 0;
        font-size: 8vh;
      }
      .label {
        color: #000;
        font-size: 7.5vh;
        vertical-align: middle;
      }
      .number-img {
        display: inline-block;
        vertical-align: middle;
        margin: 0 2em;
      }
      .button.pay {
        appearance: none;
        width: 32vh;
        height: 32vh;
        outline: 0;
        border: 0;
        cursor: pointer;
        background: url('images/btn-pay.png') center center / 100% 100% no-repeat;
        vertical-align: middle;
      }
    }
    >.button {
      appearance: none;
      position: absolute;
      top: 3vh;
      width: 15vh;
      height: 15vh;
      outline: 0;
      border: 0;
      cursor: pointer;
      &.leave {
        width: 42vh;
        height: 16vh;
        background: url('images/back_2.png') center center / 100% 100% no-repeat;
        right: 10vw;
      }
    }
    .message {
      position: absolute;
      width: 60vw;
      height: 70vh;
      left: 20vw;
      top: 15vh;
      background: url("images/confirm-popup.png") center center / 100% 100% no-repeat;
      font-size: 12vh;
      color: #000;
      padding: 1em 2em 1.3em;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .opportunity {
    @include fullscreen;
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
      height: 80vh;
      width: 120vh;
      font-size: 6vh;
      position: relative;
      &.q1 {
        background: url("images/opportunity-1.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 45%;
        }
        .question{
          width: 8.5%;
          left: 25%;
          top: 55%;
        }
      }
      &.q2 {
        background: url("images/opportunity-2.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 40%;
        }
        .question{
          width: 15%;
          left: 23%;
          top: 55%;
        }
      }
      &.q3 {
        background: url("images/opportunity-3.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 38%;
        }
        .question{
          width: 8.5%;
          left: 25%;
          top: 53%;
        }
      }
      &.q4 {
        background: url("images/opportunity-4.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 38%;
        }
        .question{
          width: 16%;
          left: 25%;
          top: 52%;
        }
      }
      &.q5 {
        background: url("images/opportunity-5.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 30%;
        }
        .question:first-child {
          width: 12%;
          left: 26%;
          top: 50%;
        }
        .question:nth-child(2) {
          width: 12%;
          left: 43%;
          top: 50%;
        }
      }
      &.q6 {
        background: url("images/opportunity-6.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 38%;
        }
        .question{
          width: 5%;
          left: 65.4%;
          top: 52%;
        }
      }
      &.q7 {
        background: url("images/opportunity-7.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 38%;
        }
        .question{
          width: 11.5%;
          left: 25.4%;
          top: 53%;
        }
      }
      &.q8 {
        background: url("images/opportunity-8.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 29%;
        }
        .question:first-child{
          width: 6.5%;
          left: 67.4%;
          top: 43%;
        }
        .question:nth-child(2){
          width: 8.5%;
          left: 53%;
          top: 65.6%;
        }
      }
      &.q9 {
        background: url("images/opportunity-9.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 47%;
        }
        .question{
          width: 12%;
          left: 23.5%;
          top: 55.3%;
        }
      }
      &.q10 {
        background: url("images/opportunity-10.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 40%;
        }
        .question{
          width: 6%;
          left: 23.4%;
          top: 56.8%;
        }
      }
      &.q11 {
        background: url("images/opportunity-11.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 48%;
        }
        .question{
          width: 18.5%;
          left: 22.4%;
          top: 60%;
        }
      }
      &.q12 {
        background: url("images/opportunity-12.jpg") center center / 100% 100% no-repeat;
        .notice {
          top: 29%;
        }
        .question{
          width: 20%;
          left: 37.6%;
          top: 50.4%;
        }
      }
      .notice {
        font-size: 70%;
        text-align: center;
        position: relative;
      }
      .question {
        position: absolute;
        height: 2em;
        background-color: #FFF;
        border: 5px solid #000;
        border-radius: .5em;
        text-align: center;
        font-size: .75em;
        transform: scaleX(.9);
        transform-origin: center;
        white-space: nowrap;
        line-height: 1.7em;
        &.show {
          display: none;
        }
      }
      .answers {
        display: flex;
        justify-content: space-around;
        position: absolute;
        width: 70%;
        left: 15%;
        top: 78%;
      }
      .answer {
        font-size: 7vh;
        color: #F00;
        cursor: grab;
        user-select: none;
        &.drag {
          opacity: 0;
        }
      }
      .dragging {
        position: absolute;
        pointer-events: none;
      }
    }
    .result {
      display: flex;
      height: 90vh;
      width: 140vh;
      .battery {
        flex: 0 0 21%;
        margin: 0 5%;
        background: url('images/battery.png') 0 center / 500% auto no-repeat;
        animation: horizon-sprite 4s steps(4) .5s forwards;
      }
      .description {
        width: 100%;
        h1 {
          font-size: 8vh;
          color: #FFF;
          text-shadow: textBorder(#4E70BE, 2px);
          text-align: center;
          margin: 0;
          font-weight: normal;
        }
        h2 {
          font-size: 5vh;
          color: #FFF;
          text-shadow: textBorder(#4E70BE, 2px);
          margin: 0;
          text-align: center;
          font-weight: normal;
        }
        .card {
          width: 100%;
          height: 60%;
        }
      }
    }
    .crow {
      position: absolute;
      left: 50%;
      height: 20vh;
      width: 57vh;
      background: url("images/crow.png") center center / 100% 100% no-repeat;
      animation: crow-fly 1.5s steps(5) forwards;
    }
  }
  .destiny {
    @include fullscreen;
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
      height: 80vh;
      width: 120vh;
      font-size: 6vh;
      position: relative;
      &.q1 {
        background: url('images/destiny-1.jpg') center center / 100% 100% no-repeat;
      }
      &.q2 {
        background: url('images/destiny-2.jpg') center center / 100% 100% no-repeat;
      }
      &.q3 {
        background: url('images/destiny-3.jpg') center center / 100% 100% no-repeat;
      }
      &.q4 {
        background: url('images/destiny-4.jpg') center center / 100% 100% no-repeat;
      }
      &.q5 {
        background: url('images/destiny-5.jpg') center center / 100% 100% no-repeat;
      }
      &.q6 {
        background: url('images/destiny-6.jpg') center center / 100% 100% no-repeat;
      }
      &.q7 {
        background: url('images/destiny-7.jpg') center center / 100% 100% no-repeat;
      }
      &.q8 {
        background: url('images/destiny-8.jpg') center center / 100% 100% no-repeat;
      }
      &.q9 {
        background: url('images/destiny-9.jpg') center center / 100% 100% no-repeat;
      }
    }
    .btn-cash {
      font-size: 6vh;
      position: absolute;
      top: 65%;
      color: #FA0;
      font-weight: bold;
      cursor: pointer;
      &::after {
        display: block;
        position: absolute;
        width: 16vh;
        height: 16vh;
        left: -16vh;
        bottom: -5vh;
        content: "";
        background: url("images/hand.png") center / 100% no-repeat;
        animation: ani-wiggle-2 1s ease-out infinite alternate;
      }
    }
  }
}

.bankrupt {
  @include fullscreen;
  background: #0F1837;
  display: flex;
  justify-content: center;
  align-items: center;
  .bankrupt-wrapper {
    width: 90vh;
    height: 90vh;
    background: url('images/bankrupt.png') center center/ 100% 100% no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: #FA0;
      font-size: 10vh;
      text-align: center;
    }
  }
}

.time-up {
  @include fullscreen;
  background: #0F1837;
  text-align: center;
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  justify-content: center;
  h1 {
    color: #FFF;
    font-size: 10vh;
    margin: 0.3em;
  }
  .sandglass {
    height: 70vh;
    width: 40vh;
    background: url('images/sandglass.png') center center/ 100% 100% no-repeat;
    margin-right: 25vh;
    transform: translateX(25vh);

  }
}

.results {
  @include fullscreen;
  background: url(images/confirm-popup.png) center center / 95% 100% no-repeat;
  .bills {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    .bill-wrapper {
      position: absolute;
      left: calc(50vw - 25vh);
      top: 0;
      list-style: none;
      opacity: 1;
      transition: left $result-during result-delay(1), opacity $result-during;
      &:nth-child(2) {
        transition: left $result-during result-delay(1, 1.5s), opacity $result-during result-delay(1, 1s);
        .character-img {
          transition: transform $result-during result-delay(1, 1.5s);
        }
      }
      &:nth-child(3) {
        transition: left $result-during result-delay(1, 2.5s), opacity $result-during result-delay(1, 2s);
        .character-img {
          transition: transform $result-during result-delay(1, 2.5s);
        }
      }
      >.money {
        opacity: 0;
        font-size: 6vh;
        color: #C72125;
        text-align: center;
        position: relative;
        top: -10vh;
        transition: opacity $result-during result-delay(2);
      }
      .bill {
        opacity: 0;
        background: url('images/bill.png') center center / auto 80% no-repeat;
        height: 80vh;
        width: 50vh;
        position: relative;
        transform-origin: 50% 0;
        transition: transform $result-during result-delay(1), opacity $result-during result-delay(1);
        .card-list {
          position: absolute;
          width: 100%;
          height: auto;
          font-size: 1.8vh;
          font-family: '王漢宗波卡體一空陰';
          color: #0f1817;
          list-style: none;
          top: 22.2%;
          left: 0;
          text-align: right;
          padding: 0 .2em;
          .card-list-item {
            line-height: 138%;
          }
        }
        .money {
          color: #C72125;
          font-size: 3vh;
          position: absolute;
          right: 3%;
          bottom: 17.7%;
        }
        &.winner {
          &+.character-img {
            &::after {
              content: '';
              display: block;
              width: 70%;
              height: 70%;
              margin: -50% auto 0 auto;
              background: url('images/crown.png') center center / 100% 100% no-repeat;
              opacity: 1;
              transition: margin $result-during result-delay(2, 2s), opacity $result-during result-delay(2, 2s);
            }
            &.jumping {
              animation: jumping-2 .3s ease-out infinite alternate;
            }
          }
          &::after {
            content: '';
            display: block;
            width: 40%;
            height: 40%;
            background: url('images/crown.png') center center / auto 100% no-repeat;
            margin: 0 auto;
            opacity: 0;
            animation: scale-out 1.5s ease-out result-delay(3) 2 alternate forwards;
          }
        }
      }
      .character-img {
        position: absolute;
        bottom: -8vh;
        left: calc(50% - 9vh);
        transform: translate(0,-50vh) scale(2);
        transition: transform $result-during result-delay(1);
        &.audio {
          transform: translate(0,-50vh) scale(1.7);
        }
        &.dryer {
          transform: translate(0,-50vh) scale(1.6);
        }
        &.water-dispenser {
          transform: translate(0,-50vh) scale(1.8);
        }
        &.tv {
          transform: translate(0,-50vh) scale(1.6);
        }
        &.water-pot {
          transform: translate(0,-50vh) scale(1.6);
        }
        &.electric-pot {
          transform: translate(0,-50vh) scale(1.6);
        }
      }
      &::after{
        content: '';
        opacity: 0;
        display: block;
        font-size: 6vh;
        position: relative;
        top: -25vh;
        text-align: center;
        transition: opacity $result-during result-delay(2);
      }
    }
    h1 {
      opacity: 0;
      position: absolute;
      bottom: 5vh;
      font-size: 8vh;
      width: 100%;
      text-align: center;
      color: #c72125;
      transition: opacity $result-during result-delay(2);
    }
    .notice {
      opacity: 0;
      position: absolute;
      font-size: 5vh;
      top: 7vh;
      width: 100%;
      text-align: center;
      color: #000;
      transition: opacity $result-during result-delay(2, 3s);
    }
    &:not(.big) {
      .bill-wrapper {
        opacity: 1;
        &::after {
          opacity: 1;
          content: '自己';
        }
        >.money {
          opacity: 1;
        }
        &:nth-child(2) {
          left: calc(97vw - 50vh);
          &::after {
            content: '電腦玩家：省省';
          }
        }
        &:nth-child(3) {
          left: 3vw;
          &::after {
            content: '電腦玩家：電電';
          }
        }
      }
      h1 {
        opacity: 1;
      }
      .notice {
        opacity: 1;
      }
    }
    &.big {
      .bill-wrapper {
        left: calc(50vw - 30vh);
        opacity: 0;
        &:first-child .bill{
          opacity: 1;
        }
        .bill {
          transform: scale(1.25);
          &.winner {
            +.character-img::after {
              margin: -100% auto 0 auto;
              opacity: 0;
            }
          }
        }
        >.money {
          opacity: 0;
        }
        &:nth-child(odd) {
          .character-img {
            transform: translate(57vh, -30vh) scale(2.5);
          }
        }
        &:nth-child(even) {
          .character-img {
            transform: translate(-50vh, -30vh) scale(2.5);
          }
        }
      }
      h1 {
        opacity: 0;
      }
      .notice {
        opacity: 0;
      }
    }
  }
}
